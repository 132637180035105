<template>
  <div class="notification-wrapper">
    <transition name="slide">
      <div v-show="show" :class="notification.style" class="notification">
        <div class="row">
          <div class="col-xs-2 icon-wrapper">
            <div class="icon row center-xs middle-xs">
              <font-awesome-icon
                class="col-xs"
                :icon="['fas', icon]"
                size="2x"
              ></font-awesome-icon>
            </div>
          </div>
          <div class="col-xs body">
            <div class="title">{{ notification.title }}</div>
            <ul class="message">
              <li
                v-for="(message, index) in notification.messages"
                :key="index"
              >
                {{ message }}
              </li>
            </ul>
          </div>
          <div class="col-xs-1 row close end-xs">
            <font-awesome-icon
              :icon="['fas', 'times']"
              size="2x"
              @click="closeNotification"
            ></font-awesome-icon>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    duration: {
      type: Number,
      default: 2000
    }
  },

  computed: {
    ...mapState({ notification: state => state.view.notification }),
    show() {
      return this.notification.show;
    },
    icon() {
      let icon = "check";
      const style = this.notification.style;
      if (style === "alert") {
        icon = "times";
      } else if (style === "info") {
        icon = "info-circle";
      }
      return icon;
    }
  },
  watch: {
    show(val) {
      if (val) {
        setTimeout(() => {
          this.$store.commit("RESET_NOTIFICATION");
        }, this.duration);
      }
    }
  },
  methods: {
    closeNotification() {
      this.$store.commit("RESET_NOTIFICATION");
    }
  }
};
</script>
<style lang="scss" scoped>
.icon {
  height: 100%;
  width: 100%;
}
.icon-wrapper {
  padding: 0;
}
.body {
  padding: 10px;
}

.notification {
  width: 400px;
  background-color: #f5f5f5;

  border-radius: 3px;
  color: #f5f5f5;
  text-align: left;

  position: fixed;
  right: 20px;
  top: 70px;
  z-index: 5;

  &.success {
    background-color: $secondary;
  }
  &.alert {
    background-color: $alert;
  }
  &.info {
    background-color: $warning;
  }
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}
.close {
  padding-left: 0;
  max-width: 29px;
  svg {
    max-width: 12px;
    cursor: pointer;
  }
}
</style>
